import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
//
import apiPlay from '../../../services/apiPlay';
//
import LoadingPlanos from './LoadingPlanos';
import CardPlano from './CardPlano';
//
import { constants } from '../../../constants';
//
import styles from './banner-planos.module.css';

export default function BannerPlanos() {


    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3.5,
        slidesToScroll: 3.5,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2.5,
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1.5,
                }
            },
            {
                breakpoint: 675,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
        ]
    };

    //STATES
    const [planos, setPlanos] = useState([]);
    const loaders = [1, 2, 3, 4]

    //EFFECTS
    useEffect(() => {

        function buscaPlanos() {

            let dados = {
                token: '640a6d8df37d38d0555cdcd998948939fa83822545653f0a38',
                parceiro: constants.api.parceiro,
            }

            apiPlay.post('/api/planos/visualizar', dados)
                .then(response => {
                    setPlanos(response.data.personalizado);
                }).catch(error => {
                    console.log('error', error);
                })

        }

        buscaPlanos();

    }, [])

    //BODY
    return (
        <section id='planos' className={styles['container']}>
            <div className={styles['lista-planos']} >
                <Slider {...settings}>
                    {planos.length < 1
                        ? loaders.map(loader => <LoadingPlanos key={loader} />)
                        : planos
                            .filter(plano => plano.mostraApp)
                            .sort((a, b) => a.value - b.value)
                            .map(plano => <CardPlano plano={plano} key={plano.id} />)
                    }

                </Slider>
            </div>
        </section>
    )
}
