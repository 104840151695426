import React from 'react'
//
import DefaultHeader from '../../../components/DefaultHeader';
import PerguntaFaq from './PerguntaFaq';
//
import { constants } from '../../../constants';
//
import { FaQuestion } from "react-icons/fa";
//
import styles from './banner-faq.module.css';

export default function BannerFaq() {

  //STATES
  const peguntas = [
    {
      id: 1,
      title: 'É necessário pagar alguma taxa para aderir ao plano?',
      text: 'Não há cobrança de taxa de adesão ao Plano. Para aderir ao plano, o Cliente deve adquirir e ativar previamente o chip da ' + constants.texts.nomeEmpresa + ' no aplicativo. A ativação do plano é feita automaticamente após a realização da primeira recarga, no valor mínimo de R$ 34,90 (Trinta e quatro reais e noventa centavos), após o isso o Cliente receberá automaticamente a confirmação de ativação do plano por SMS e/ou App',
    },
    {
      id: 2,
      title: 'Como faço a portabilidade para a ' + constants.texts.nomeEmpresa,
      text: 'O titular da conta pode utilizar o nosso aplicativo ou o CHAT para fazer a solicitação. Serão solicitadas as seguintes informações: Nome e CPF, número que deseja manter e operadora antiga. É importante que as linhas estejam no mesmo CPF e DDD. Pronto! Em até 5 dias úteis, o Plano ' + constants.texts.nomeEmpresa +' estará disponível no seu número.',
    },
    {
      id: 3,
      title: 'Como é feita a renovação do plano?',
      text: 'A renovação do plano é automática, e ocorre no dia seguinte ao término da validade do mesmo, que é de 30 (trinta) dias. Para isso, o Cliente precisa efetuar o pagamento da sua próxima assinatura através de um dos métodos disponíveis no aplicativo '+ constants.texts.nomeEmpresa +' (Pix, Boleto e Cartão de Crédito ou Débito). No entanto, o cliente tem a opção de manifestar-se em contrário, pela não renovação do plano, podendo ainda optar por outra promoção em vigor, se assim o desejar. Basta efetuar a solicitação pelo APP ' + constants.texts.nomeEmpresa + '.',
    },
    {
      id: 4,
      title: 'É possível fazer ligações internacionais (DDI)?',
      text: 'Não é possível. Os benefícios dos serviços de voz/sms do plano não são válidos para ligações internacionais.',
    },
  ]


  //BODY
  return (
    <section id='faq' className={styles['container']}>
      <DefaultHeader
        icon={<FaQuestion />}
        subtitle='Perguntas Frequentes'
        title='Encontre as respostas para as perguntas que você talvez tenha, e, caso não encontre, entre em contato conosco!'
      />
      <div className={styles['perguntas-faq']}>
        {peguntas.map(pergunta => {
          return (
            <PerguntaFaq
              key={pergunta.id}
              title={pergunta.title}
              text={pergunta.text}
            />
          );
        })}
      </div>
    </section>
  )
}
