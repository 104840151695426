export const constants = {
    
    api:{
        parceiro: 'INFOTEC',
    },

    texts:{
        nomeEmpresa: 'INFOTEC',
    },

    links:{
        linkPedirChip: 'https://api.whatsapp.com/send?phone=5579999866695&text=Preciso%20da%20ajuda%20do%20suporte%20t%C3%A9cnico%20da%20*INFOTEC*',
        linkApp: 'https://play.google.com/store/apps/details?id=app.mobile.infotec&hl=pt',
        linkWpp: 'https://api.whatsapp.com/send?phone=5579999866695&text=Preciso%20da%20ajuda%20do%20suporte%20t%C3%A9cnico%20da%20*INFOTEC*',
        linkMapaCobertura: 'https://tim.img.com.br/mapa-cobertura/',
        linkTenhaSuaOperadora: null,
        // linkTenhaSuaOperadora: 'https://api.whatsapp.com/send?phone=5561920040101&text=quero%20saber%20mais', // INFINITI Apenas
        linkRastrearMeuPedido: 'https://tracking.totalexpress.com.br/',
        linkPoliticaDePrivacidade: 'https://privacidade.operadora.app.br/#/infiniti',
        linkInstagram: 'https://instagram.com/infotecprovedor?igshid=MzRlODBiNWFlZA==',
    },

}